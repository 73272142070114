/* NavBar Styling */

/* General styles for the navBar */
.navBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #FB7503;
  padding: 1rem;
  color: white;
  margin-bottom: 25px;
}

.navBar .logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.navBar .navLinks {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.navBar .navLinks li {
  margin: 0 15px;
}

.navBar .navLinks a {
  color: white;
  text-decoration: none;
}

.navBar .navLinks a:hover {
  text-decoration: underline;
}

.navBar .navExtras {
  display: flex;
  align-items: center;
}

.navBar .searchBar {
  padding: 5px;
  margin-right: 15px;
}

.navBar .profileIcon {
  cursor: pointer;
}

/* ================================== */

/* Test Borders */
.borderRed{
  border: solid 5px red;
}

.borderBlue{
  border: solid 5px blue;
}

.borderGreen{
  border: solid 5px green;
}

/* ================================== */


/* Primary Colors */
.prim_color1 { background-color: #FF5722; } /* Deep Orange */
.prim_color2 { background-color: #FF7043; } /* Lighter Orange */
.prim_color3 { background-color: #FF8A65; } /* Even Lighter Orange */
.prim_color4 { background-color: #FFAB91; } /* Pale Orange */
.prim_color5 { background-color: #FFCCBC; } /* Very Pale Orange */

/* Secondary Colors */
.sec_color1 { background-color: #FF9800; } /* Amber */
.sec_color2 { background-color: #FFB74D; } /* Light Amber */
.sec_color3 { background-color: #FFCC80; } /* Lighter Amber */
.sec_color4 { background-color: #FFE0B2; } /* Pale Amber */
.sec_color5 { background-color: #FFF3E0; } /* Very Pale Amber */

/* Text Styles */
h1 {
  font-size: 2.5em;
  font-weight: bold;
  color: #FF5722;
}

h2 {
  font-size: 2em;
  font-weight: bold;
  color: #FF7043;
}

h3 {
  font-size: 1.75em;
  font-weight: bold;
  color: #FF8A65;
}

h4 {
  font-size: 1.5em;
  font-weight: bold;
  color: #FFAB91;
}

h5 {
  font-size: 1.25em;
  font-weight: bold;
  color: #FFCCBC;
}

h6 {
  font-size: 1em;
  font-weight: bold;
  color: #FF9800;
}

p {
  font-size: 1em;
  line-height: 1.5;
  color: #333;
}

.accent {
  font-size: 1em;
  color: #FF7043;
  font-style: italic;
}

.container {
  width: 100%;
  max-width: 1200px;
  min-height: 300px;
  margin: 0 auto;
  padding: 0 15px;
  border: solid 5px red;
}

